import { NavbarBody, NavbarTitle, useForwardUtm, useOpenChat } from '@orus.eu/pharaoh'
import { useMatches, type FileRoutesByPath } from '@tanstack/react-router'
import { useCallback, useState, type ReactNode } from 'react'
import { trpcReact } from '../../client'
import { useEmbeddingPartnerConfiguration } from '../../lib/embedding-partner'
import { useDisconnect } from '../../lib/hooks/use-disconnect'
import { getMutaSituation } from '../../lib/muta-util'
import { useSession } from '../../lib/session'
import { cguLink, confidentialityLink } from '../../lib/static-links'
import { usePermissions } from '../../lib/use-permissions'
import { InviteToLoginPage } from './invite-to-login/InviteToLoginPage'
import { MenuPage } from './menu-page'
import { AgentBlocker } from './no-orus-or-partner-agents'

export default function CustomerPage({ children }: { children: ReactNode }): ReactNode {
  const forwardUtm = useForwardUtm()
  const session = useSession()
  const { permissions } = usePermissions()
  const disconnect = useDisconnect()
  const embeddingPartnerConfiguration = useEmbeddingPartnerConfiguration()
  const openChat = useOpenChat()
  const matches = useMatches()

  const withoutLayout = matches.some((match) => ROUTE_IDS_WITHOUT_LAYOUT.has(match.id))

  // To prevent the error: "Rendered fewer hooks than expected. This may be caused by an accidental early return statement."
  const [isDisconnect, setIsDisconnect] = useState(false)

  const embeddingPartnerReturnUrl = embeddingPartnerConfiguration?.returnUrl

  const returnToEmbeddingPartner = useCallback(() => {
    if (embeddingPartnerReturnUrl) {
      document.location.href = embeddingPartnerReturnUrl
    }
  }, [embeddingPartnerReturnUrl])

  if (!session.user && !isDisconnect) return <InviteToLoginPage />
  if (['platform', 'partner'].includes(session.permissions.type) && session.user && 'email' in session.user)
    return <AgentBlocker email={session.user.email} />
  if (session.permissions?.type !== 'client') return <InviteToLoginPage />

  const [contracts] = trpcReact.contracts.listMyContracts.useSuspenseQuery()
  const { isMuta, isMutaOnly } = getMutaSituation(contracts)
  const operatingZone = contracts[0]?.operatingZone ?? 'fr'

  return withoutLayout ? (
    children
  ) : (
    <MenuPage
      menuTitle={<NavbarTitle variant="clientapp" />}
      menuBody={
        <NavbarBody
          variant="clientapp"
          disconnect={() => {
            setIsDisconnect(true)
            disconnect()
          }}
          displayHealthInsurance={isMuta}
          displayClaims={!isMutaOnly}
          handleHelp={openChat}
          confidentialityLink={forwardUtm(confidentialityLink[operatingZone])}
          cguLink={forwardUtm(cguLink[operatingZone])}
          embeddingPartner={
            embeddingPartnerConfiguration
              ? { name: embeddingPartnerConfiguration.name, returnToPartner: returnToEmbeddingPartner }
              : undefined
          }
          permissions={permissions}
        />
      }
      pageContent={children}
    />
  )
}

const ROUTE_IDS_WITHOUT_LAYOUT = new Set<string>(['/_customer/s/r/$token'] satisfies (keyof FileRoutesByPath)[])
